.classic-search-dropdown-container {
  width: 100%;
  position: relative;
}
.classic-search-dropdown-label {
  font-weight: bold;
  color: #223242;
  padding-bottom: 5px;
}

.classic-search-dropdown-box {
  border-style: solid;
  border-width: 1px;
  border-color: #bebebe;
  border-radius: 5px;
  padding: 0 15px;

  height: 50px;
  width: 100%;

  overflow: hidden;

  font-size: 15px;
  display: flex;
  align-items: center;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.171);
}

.classic-search-dropdown-suggestion-container {
  border-radius: 5px;
  padding: 5px 15px;

  margin-bottom: 35px;

  overflow: scroll;
  position: absolute;
  z-index: 10;
  max-height: 200px;
  width: 100%;

  background-color: white;

  font-size: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.171);
}

.classic-search-dropdown-not-selected {
  color: #8b8b8b;
}

.classic-search-dropdown-suggestion-option {
  font-size: 15px;
  font-weight: bold;
  color: #223242;
  border-radius: 5px;
  padding: 5px;

  width: 100%;
  cursor: pointer;
}

.classic-search-dropdown-suggestion-option:hover {
  background: rgba(197, 197, 197, 0.288);
}

.classic-search-dropdown-label-required {
  color: red;
}
