select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='current' opacity='0.65'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat;
  background-size: 12px;
  background-position: calc(100% - 16px) calc(50% + 3px);
  background-repeat: no-repeat;
}

select::before {
  content: '';
  position: absolute;
  width: 6px;
  height: 2px;
  background-color: black;
  transform: rotate(45deg);
  right: 0;
  top: 0;
  z-index: 1;
}
