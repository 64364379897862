.text-input-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.text-input {
  border-style: solid;
  border-width: 1px;
  border-color: #bebebe;
  border-radius: 5px;
  padding: 0 15px;

  height: 50px;
  width: 100%;

  overflow: hidden;

  font-size: 15px;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.171);
}

.text-input-label {
  font-weight: bold;
  color: #223242;
}

.text-input-description {
  color: #8b8b8b;
}

.text-input-error {
  color: red;
  font-size: 15px;
}
