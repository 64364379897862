.radio-option {
  position: relative;
}

.radio-option::before {
  position: absolute;
  content: '';
  top: 50%;
  left: 0.85rem;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  opacity: 1;
  transition: all 0.3s ease;
  border: 2px solid;
  @apply bg-gray-300 border-gray-300;
}

.radio-option.checked {
  background-color: #009eff;
  @apply shadow text-white;
}

.radio-option.checked::before {
  background-color: #009eff;
  @apply border-white;
}

.radio-option span {
  margin-left: 1.5rem;
}
